@import 'assets/styles/mixins.scss';

.breadcrumbs {
  padding: rem(10) rem(20);
  // background-color: #141414;
  // border-bottom: 1px solid $gray-border;
}

.path {
  font-size: rem(18);
  color: #dedede;
}

.arrow {
  display: inline-block;
  margin: 0 rem(10);
  &:after {
    display: inline-block;
    content: '·';
  }
}
