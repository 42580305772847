$background-card: #141414;

@import "~bootstrap-css-only/css/bootstrap.min.css";
@import "assets/styles/Antd/antd.cleanui";
@import "assets/styles/CleanUI/cleanui";
@import "assets/styles/Bootstrap/bootstrap.cleanui";
@import "assets/styles/Chartist/chartist.cleanui";
@import "assets/styles/Nprogress/nprogress.cleanui";
@import "assets/styles/Rcdrawer/rcdrawer.cleanui";
@import "~c3/c3.min.css";
@import "~react-datasheet/lib/react-datasheet.css";

@font-face {
  font-family: 'Montserrat';
  src: local('Lato'), url(./fonts/montserrat/Montserrat-Light.otf) format('opentype');
}

.data-editor {
  border: 0px !important;
  height: 19px !important;
  min-width: 50px !important;
}

.value-viewer{
  min-width: 50px !important;

}

pre {
  color: #ffffff;
  padding: 1.53rem;
  border-radius: 3px;
  border: 1px solid #2e2e2e;
  background: #3f3f3f;
}

body {
  background-color: black;
  font-family: 'Montserrat', sans-serif !important;
}

button {
  border-radius: 20px !important;
}

th {
  background-image: linear-gradient(#000000 30%, #132e45 100%) !important;
}

table th:nth-child(1){
  border-radius: 10px 0px 0px 10px !important;
}
table th:nth-last-child(1){
  border-radius: 0px 10px 10px 0px !important;
}

.card {
  border: none;
  background: $background-card;
}

.card-header {
  background: $background-card;
}

.topbar {
  background-color: $background-card;
}

.ant-menu-item.ant-menu-item-active,
.ant-menu-item.ant-menu-item-selected {
  // background-color: $primary !important;
  background-image: linear-gradient(to right, #000000, #09212c);
  border-radius: 30px;
}


td.cell.read-only {
  background-color: #222 !important;
  color: #ddd !important;
  opacity: 0.8 !important;
}

td.cell {
  border-color: #000 !important;
}

.cell {background-color: #333 }

.header-table td.cell {
  font-weight: 500 !important;
  background-color: #222 !important;
  padding: 7px 0px !important;
  opacity: 1 !important;
  color: rgba(255, 255, 255, 0) !important;
  
  & > input {
    // color: transparent !important;
    font-size: 12px;
  }
}

.header-table td.cell:nth-child(1) {
  padding-top: 30px !important;
  font-size: 20px;
  color: #ddd !important;
}

.ant-descriptions-item-label {
  font-weight: bold !important;
  font-size: 15px !important;
}

.ribbon {
  width: auto;
  height: 50px;
  line-height: 50px;
  padding: 0px 30px;
  position: absolute;
  left: -8px;
  top: 20px;
  background: #166f94;
  font-size: 20px;
  font-weight: bold;
}
.ribbon:before, .ribbon:after {
  content: "";
  position: absolute;
}
.ribbon:before {
  height: 0;
  width: 0;
  top: -8.5px;
  left: 0.1px;
  border-bottom: 9px solid #034865;
  border-left: 9px solid transparent;
}
.ribbon:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 15px solid #166f94;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #0190fe !important;
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #0190fe !important;
}

.ant-select-dropdown-menu-item-selected {
  background-color: #0190fe !important;
}

.ant-select-dropdown-menu-item-active {
  background-color: #3084c5 !important;
}


.app-wrapper {
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.app-main {
  background-color: #222 !important;
  padding: 30px;
  border-radius: 10px;
}

input[placeholder="Escribe tu mensaje"]{
  color: #000 !important;
}
