.plan {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__radio{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  &__cards{
    display: flex;
    justify-content: space-around;
    height: 90%;
  }
  &__card {
    border-radius: 10px;
    background: linear-gradient(#858891, #334168);
    height: 300px;
    width: 350px;
    padding: 10px;
  }
  &__description {
    height: 60%;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    margin: 20px auto;
  }
  &__price {
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__button {
    margin: 20px auto;
    width: 100%;
  }
}